import axios from 'axios'
import {
  localStorageDelete,
  localStorageGet,
  localStorageSet,
} from 'utils/localStorage'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

const getRequest = async (url: string) => {
  const response = await axiosClient.get(`${url}`)
  return response.data
}

const postRequest = async (url: string, body: object) => {
  const response = await axiosClient.post(`${url}`, body)
  return response.data
}

const putRequest = async (url: string, body: object) => {
  const response = await axiosClient.put(`${url}`, body)
  return response.data
}

const patchRequest = async (url: string, body: object) => {
  const response = await axiosClient.patch(`${url}`, body)
  return response.data
}

const deleteRequest = async (url: string) => {
  const response = await axiosClient.delete(`${url}`)
  return response.data
}

// Set the AUTH token for any request
axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorageGet('token', false)
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers['x-access-token'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosClient.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if (
      originalConfig.url !== '/auth/login' &&
      originalConfig.url !== '/auth/register' &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const response = await postRequest('/auth/token/refresh', {
            refreshToken: localStorageGet('refreshToken', false),
          })

          localStorageSet('token', response.token)
          localStorageSet('refreshToken', response.refreshToken)

          return await axiosClient(originalConfig)
        } catch (error) {
          localStorageDelete()
          return Promise.reject(error)
        }
      }
    }

    return Promise.reject(err)
  },
)

const AxiosClient = {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
}

export default AxiosClient
