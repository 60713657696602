import { DefaultModel } from './model'

type User = DefaultModel & {
  email: string
  firstname: string
  lastname: string
  phone: string
  caterer: Caterer
}

type ServiceClothes = {
  whiteShirt?: boolean
  blackShirt?: boolean
  suitJacket?: boolean
  bowTie?: boolean
  tie?: boolean
  vest?: boolean
}
type CookClothes = {
  whiteVest?: boolean
  blackVest?: boolean
}

type Caterer = DefaultModel & {
  company: string
  clothes: { service: ServiceClothes; cook: CookClothes }
  visiblePlannings?: Array<string>
  user: User
}

type UserForm = {
  email: string
  firstname: string
  lastname: string
  phone: string
  caterer: {
    company: string
    clothes: { service: ServiceClothes; cook: CookClothes }
  }
}

type Password = {
  currentPassword?: string
  newPassword: string
  confirmPassword: string
}

type UserStoreState = {
  isAuthenticated: Boolean
  isActive: Boolean
  isAdmin: Boolean
  current: User | any | undefined
  profileComplete: Boolean
}

export enum UserReducerActions {
  Login = 'LOG_IN',
  Signup = 'SIGN_UP',
  Update = 'UPDATE',
  Logout = 'LOG_OUT',
}

export type {
  User,
  UserForm,
  Caterer,
  Password,
  ServiceClothes,
  CookClothes,
  UserStoreState,
}
