import { Suspense, lazy } from 'react'
import type { RouteObject } from 'react-router'
import { CircularProgress } from '@mui/material'

const Loadable = (Component: any) =>
  function loading(props: any) {
    return (
      <Suspense
        fallback={<CircularProgress sx={{ m: 'auto' }} color="secondary" />}>
        <Component {...props} />
      </Suspense>
    )
  }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotImplemented = Loadable(
  lazy(() => import('pages/NotImplemented/NotImplemented')),
)

const NotFound = Loadable(lazy(() => import('pages/NotFound/NotFound')))
const Login = Loadable(lazy(() => import('pages/Login/Login')))
const Register = Loadable(lazy(() => import('pages/Register/Register')))
const Home = Loadable(lazy(() => import('pages/Home/Home')))
const Dashboard = Loadable(lazy(() => import('pages/Dashboard/Dashboard')))
const OrderList = Loadable(lazy(() => import('pages/Order/OrderList')))
const OrderCreate = Loadable(lazy(() => import('pages/Order/OrderCreate')))
const Order = Loadable(lazy(() => import('pages/Order/Order')))
const Profile = Loadable(lazy(() => import('pages/User/Profile')))
const ForgotPassword = Loadable(lazy(() => import('pages/User/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('pages/User/ResetPassword')))
const Planning = Loadable(lazy(() => import('pages/Planning/Planning')))

const guestRoutes: RouteObject[] = [
  {
    path: '/',
    id: 'home',
    element: <Home />,
  },
  {
    path: 'connexion',
    id: 'login',
    element: <Login />,
  },
  {
    path: 'inscription',
    id: 'register',
    element: <Register />,
  },
  {
    path: 'mot-de-passe-oublie',
    id: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'mot-de-passe-oublie/:token',
    id: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: '*',
    id: 'notFound',
    element: <NotFound />,
  },
]

const authRoutes: RouteObject[] = [
  {
    path: 'tableau-de-bord',
    id: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'mon-compte',
    id: 'profile',
    element: <Profile />,
  },
  {
    path: 'nouvelle-commande',
    id: 'order-create',
    element: <OrderCreate />,
  },
  {
    path: 'commande/:id',
    id: 'order',
    element: <Order />,
  },

  {
    path: 'historique-des-commandes',
    id: 'orders-history',
    element: <OrderList past />,
  },
  {
    path: 'toutes-les-commandes',
    id: 'orders-all',
    element: <OrderList />,
  },
  {
    path: 'commandes',
    id: 'orders',
    element: <OrderList future />,
  },
  {
    path: 'planning',
    id: 'planning',
    element: <Planning />,
  },
  {
    path: '*',
    id: 'notFound',
    element: <NotFound />,
  },
]

type Routes = {
  public: Array<RouteObject>
  private: Array<RouteObject>
}
const routes: Routes = {
  public: guestRoutes,
  private: authRoutes,
}

export default routes
